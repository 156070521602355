body {
  color: #4A4E69; }

nav.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; }

nav.bg-success {
  background-color: #28a219;
  color: #ffffff; }
  nav.bg-success .navbar-brand, nav.bg-success .navbar-brand:hover, nav.bg-success .navbar-brand:visited {
    color: #ffffff; }
  nav.bg-success .navbar-nav .nav-link, nav.bg-success .navbar-nav .nav-link:hover, nav.bg-success .navbar-nav .nav-link:visited {
    color: #ffffff; }
  nav.bg-success .navbar-nav .btn {
    background-color: #FFFFFF;
    color: #28a219 !important;
    text-transform: uppercase;
    font-weight: 400; }
  nav.bg-success .navbar-text * {
    color: #ffffff; }
    nav.bg-success .navbar-text * i {
      font-size: 1.2em;
      padding: 0 3px; }

nav.bg-success.active {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6); }

.main {
  margin-top: 35px; }
  .main .row {
    padding: 65px 50px; }
  .main .row:nth-child(even) {
    background-color: lightgrey;
    -webkit-clip-path: polygon(0 0%, 100% 4%, 100% 96%, 0% 100%);
    clip-path: polygon(0 0%, 100% 4%, 100% 96%, 0% 100%); }

.footer .row {
  padding: 25px 50px;
  background-color: #0e384a;
  -webkit-clip-path: polygon(0 0%, 100% 4%, 100% 96%, 0% 100%);
  clip-path: polygon(0 0%, 100% 4%, 100% 96%, 0% 100%); }

.footer .row * {
  color: #ffffff; }

.footer .row i {
  font-size: 1.2em;
  padding: 0 3px; }

h1, h2, h3, h4, h5 {
  font-weight: 300;
  font-family: 'Open Sans', sans-serif; }

p {
  font-weight: 300;
  font-family: 'Open Sans', sans-serif; }

.profile-image {
  box-shadow: 0px 4px 6px 2px lightgrey;
  background-color: lightgray;
  width: 200px;
  height: 200px;
  border-radius: 50%; }

.centered {
  text-align: center; }

.timeline_element, .timeline_element-contents {
  overflow: hidden; }

.timeline_element {
  position: relative; }

.timeline {
  margin-left: 0; }

.timeline {
  font-size: 16px;
  margin-left: -50px; }

@media screen and (max-width: 1000px) {
  .timeline {
    margin-left: 0; } }

.timeline > ul, .timeline > ul > li {
  margin: 0;
  padding: 0; }

.timeline > ul > li {
  display: block; }

@media screen and (max-width: 567px) {
  .timeline_element {
    border-top: 1px solid #aaa;
    padding-top: 15px !important;
    margin-top: 15px !important; }
  .timeline_element-date {
    color: #0e384a; } }

@media screen and (min-width: 568px) {
  .timeline_element-date {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 180px;
    padding: 30px 15px;
    color: #0e384a;
    text-align: right;
    font-style: italic;
    line-height: 30px;
    border-right: 1px solid #4A4E69; }
  .timeline_element-date:after {
    content: " ";
    position: absolute;
    top: 42.5px;
    right: -6px;
    display: block;
    width: 10px;
    height: 10px;
    background-color: #28a219; }
  .timeline_element:first-child .timeline_element-date {
    padding-top: 0;
    line-height: 1.5;
    margin-top: 10px; }
  .timeline_element:first-child .timeline_element-date .timeline_element-date-text {
    margin-top: -10px;
    display: block; }
  .timeline_element:first-child .timeline_element-date:after {
    top: 0; } }

.timeline_element-contents {
  padding: 30px 0;
  margin-left: 200px; }

@media screen and (max-width: 567px) {
  .timeline_element-contents {
    margin-left: 0;
    padding: 15px 0 0; }
  .timeline.talks-timeline .timeline_element {
    border-top: 1px solid #aaa; } }

@media screen and (min-width: 568px) {
  .timeline_element + .timeline_element .timeline_element-contents {
    border-top: 1px solid #aaa; }
  .timeline.portfolio-timeline .timeline_element:first-child .timeline_element-contents, .timeline_element:first-child .timeline_element-contents {
    padding-top: 0; }
  .timeline.portfolio-timeline .timeline_element:first-child .timeline_element-date {
    padding-top: 0;
    line-height: 1.5;
    margin-top: 10px; }
  .timeline.portfolio-timeline .timeline_element:first-child .timeline_element-date .timeline_element-date-text {
    margin-top: -10px;
    display: block; }
  .timeline.portfolio-timeline .timeline_element:first-child .timeline_element-date:after {
    top: 0; } }

.project-description ul {
  list-style-type: square; }

.card-deck {
  margin-top: 15px; }

ul.horizontal {
  list-style-type: none; }
  ul.horizontal li {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 200px;
    padding: 5px 10px; }
    ul.horizontal li img {
      height: 64px;
      width: 100%; }
    ul.horizontal li img.squared {
      height: 64px;
      width: auto; }
    ul.horizontal li a {
      color: #4A4E69; }

a {
  color: #4A4E69; }

.about-text p {
  text-align: justify;
  font-size: 1.4em; }

.highlight {
  display: inline-block;
  background-color: #28a219;
  padding: 3px 5px;
  color: white; }

.btn-succes {
  background-color: #28a219; }

.card .img-container {
  max-height: 160px;
  overflow: hidden; }

.card-body .card-text {
  text-align: justify;
  font-size: 0.9em;
  min-height: 100px; }

.card-body .card-title {
  font-size: 1.2em; }

.card-body .card-subtitle {
  font-size: 0.9em;
  font-weight: 400; }

.card-body .tags {
  font-size: 0.7em;
  font-weight: 100;
  padding: 5px 0;
  min-height: 40px; }

p.section-descr {
  font-size: 1.1em; }

.locale-ru, .locale-us {
  height: 24px;
  width: 24px;
  display: inline-block;
  margin: 0 3px;
  cursor: pointer; }

.locale-ru {
  background-image: url(/assets/locale-ru.png); }

.locale-us {
  background-image: url(/assets/locale-us.png); }
